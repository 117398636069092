import revive_payload_client_4ZL5xJCjLr from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_r4k4na7twr6hx75mqal77mx5hm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_F2J5QNZndc from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_r4k4na7twr6hx75mqal77mx5hm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vddCJYkrOz from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_r4k4na7twr6hx75mqal77mx5hm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_swYxetusyX from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_r4k4na7twr6hx75mqal77mx5hm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LslhSXQXHE from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_r4k4na7twr6hx75mqal77mx5hm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_CmbGQmCRHy from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_7q4fVnd9MX from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_r4k4na7twr6hx75mqal77mx5hm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vant_lazyload_plugin_W13KNvxip6 from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/.nuxt/vant-lazyload.plugin.mjs";
import plugin_sP80iFPEAo from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@4.18.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_mkRk4pFumn from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_LR9lsXMKyD from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_r4k4na7twr6hx75mqal77mx5hm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_blahybtbCz from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.18.0_vue@3.4.27__pinia@2.1_runjj3gkcsudmegabsrbl22ft4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import global_components_PEc2PKHunz from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/global-components.ts";
import event_bus_S6Iq1iGe5d from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/event-bus.ts";
import my_directive_DkZs1AscUH from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/my-directive.ts";
import plyr_client_AWuhaKXXrg from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/plyr.client.ts";
import tweetTracking_rwcgE0a16J from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/tweetTracking.ts";
export default [
  revive_payload_client_4ZL5xJCjLr,
  unhead_F2J5QNZndc,
  router_vddCJYkrOz,
  payload_client_swYxetusyX,
  check_outdated_build_client_LslhSXQXHE,
  plugin_vue3_CmbGQmCRHy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7q4fVnd9MX,
  vant_lazyload_plugin_W13KNvxip6,
  plugin_sP80iFPEAo,
  i18n_mkRk4pFumn,
  chunk_reload_client_LR9lsXMKyD,
  plugin_blahybtbCz,
  global_components_PEc2PKHunz,
  event_bus_S6Iq1iGe5d,
  my_directive_DkZs1AscUH,
  plyr_client_AWuhaKXXrg,
  tweetTracking_rwcgE0a16J
]
import { default as icons_45pageWac7Gvo7yWMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.18.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as indexNNRsKJSCSLMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/about/index.vue?macro=true";
import { default as indexRhIW2K33IOMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/blocks/index.vue?macro=true";
import { default as indexHj84qFDJlxMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/bookmarks/index.vue?macro=true";
import { default as index1PjdqF49aaMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/confirm/index.vue?macro=true";
import { default as _91id_936eHd8C5uhKMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/DM/Message/[id].vue?macro=true";
import { default as _91id_93dwy5UkJeOPMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/embed/[acct]/status/[id].vue?macro=true";
import { default as _91_tag_93DmXbjseTOLMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/explore/hashtag/[_tag].vue?macro=true";
import { default as index9MEUjruvCAMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/followRequests/index.vue?macro=true";
import { default as indexKuLlmCumD2Meta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/index.vue?macro=true";
import { default as _91id_93Jf2dyzJmlJMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/lists/[id].vue?macro=true";
import { default as indexDQLvg9WPrmMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/lists/index.vue?macro=true";
import { default as _91_91toId_93_93O5X4D4W2AXMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Message/[[toId]].vue?macro=true";
import { default as indexulNSVtDzmhMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/mutes/index.vue?macro=true";
import { default as listVGKL7kOGPgMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/components/list.vue?macro=true";
import { default as notificationmfBVZ71W3xMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/components/notification.vue?macro=true";
import { default as indexhHug3F68xJMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/index.vue?macro=true";
import { default as index4PEouCpCIhMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/password/reset/index.vue?macro=true";
import { default as index66JXbCQmmMMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/public/common/index.vue?macro=true";
import { default as indexQc13JLv7haMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/public/local/index.vue?macro=true";
import { default as registeraAjicikz2eMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/register.vue?macro=true";
import { default as rulesver12DXwzzMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/rules.vue?macro=true";
import { default as verifyEmailrNcFMh4TzJMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/verifyEmail.vue?macro=true";
import { default as indexV2o3RalCsRMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/index.vue?macro=true";
import { default as _91_type_93ZpDmNCzbPAMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Search/[_type].vue?macro=true";
import { default as index2epwJWfGdlMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Search/index.vue?macro=true";
import { default as indexyep89XGvHcMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/send/index.vue?macro=true";
import { default as _91id_93quU0DZ0BiXMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminAccounts/detail/[id].vue?macro=true";
import { default as indexoiqebpOf7ZMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminAccounts/index.vue?macro=true";
import { default as editi2kgDog86JMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/edit.vue?macro=true";
import { default as index34UD9wfn9ZMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/index.vue?macro=true";
import { default as newffbUjq2DsdMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/new.vue?macro=true";
import { default as _91domain_93aabXLBaqToMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminInstances/detail/[domain].vue?macro=true";
import { default as indexTbg8k4UI7FMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminInstances/index.vue?macro=true";
import { default as editG2QPyWkF6jMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/edit.vue?macro=true";
import { default as indexe3bGp01hgZMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/index.vue?macro=true";
import { default as newwkB5a5PFICMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/new.vue?macro=true";
import { default as indexNKLGV0OWOCMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/dashboard/index.vue?macro=true";
import { default as indexx1eA7tzdYWMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/emailDomainBlocks/index.vue?macro=true";
import { default as newo8l4RGh6qUMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/emailDomainBlocks/new.vue?macro=true";
import { default as index8TqdMcGDxzMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/export/index.vue?macro=true";
import { default as configdXT5FQ5kllMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/followRecommend/config.js?macro=true";
import { default as indexg9XzoskwPgMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/followRecommend/index.vue?macro=true";
import { default as indexjj9qlcomA3Meta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/import/index.vue?macro=true";
import { default as addYEzoDNhEKJMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/add.vue?macro=true";
import { default as configYTGvvalqPRMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/config.js?macro=true";
import { default as indexs6Xv2qX75NMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/index.vue?macro=true";
import { default as index7FMizl1yIVMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/nsq/index.vue?macro=true";
import { default as indexz2dF71HFLTMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/profile/index.vue?macro=true";
import { default as indexEN5QR9p1LtMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/relayStation/index.vue?macro=true";
import { default as new0P7iVRUZ7aMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/relayStation/new.vue?macro=true";
import { default as indexCfPwC9rGoOMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/report/index.vue?macro=true";
import { default as indexQuHi0v4h4jMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/rule/index.vue?macro=true";
import { default as index4iPPjRwiHUMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/serverSetup/index.vue?macro=true";
import { default as indexxMyLzV2jAvMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/subscription/index.vue?macro=true";
import { default as indexg18OdWe2OXMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/subscribed/index.vue?macro=true";
import { default as followerzJuYy12239Meta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/follower.vue?macro=true";
import { default as following8MtoSQTtauMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/following.vue?macro=true";
import { default as indexL5zv60byO6Meta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/index.vue?macro=true";
import { default as _91id_93i8tgZw29SFMeta } from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/status/[id].vue?macro=true";
export default [
  {
    name: icons_45pageWac7Gvo7yWMeta?.name ?? "icons-page",
    path: icons_45pageWac7Gvo7yWMeta?.path ?? "/_icons",
    meta: { ...(icons_45pageWac7Gvo7yWMeta || {}), ...{"layout":"svg-sprite"} },
    alias: icons_45pageWac7Gvo7yWMeta?.alias || [],
    redirect: icons_45pageWac7Gvo7yWMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.18.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: indexNNRsKJSCSLMeta?.name ?? "about___en",
    path: indexNNRsKJSCSLMeta?.path ?? "/en/about",
    meta: indexNNRsKJSCSLMeta || {},
    alias: indexNNRsKJSCSLMeta?.alias || [],
    redirect: indexNNRsKJSCSLMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexNNRsKJSCSLMeta?.name ?? "about___zh",
    path: indexNNRsKJSCSLMeta?.path ?? "/about",
    meta: indexNNRsKJSCSLMeta || {},
    alias: indexNNRsKJSCSLMeta?.alias || [],
    redirect: indexNNRsKJSCSLMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexRhIW2K33IOMeta?.name ?? "blocks___en",
    path: indexRhIW2K33IOMeta?.path ?? "/en/blocks",
    meta: indexRhIW2K33IOMeta || {},
    alias: indexRhIW2K33IOMeta?.alias || [],
    redirect: indexRhIW2K33IOMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/blocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRhIW2K33IOMeta?.name ?? "blocks___zh",
    path: indexRhIW2K33IOMeta?.path ?? "/blocks",
    meta: indexRhIW2K33IOMeta || {},
    alias: indexRhIW2K33IOMeta?.alias || [],
    redirect: indexRhIW2K33IOMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/blocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexHj84qFDJlxMeta?.name ?? "bookmarks___en",
    path: indexHj84qFDJlxMeta?.path ?? "/en/bookmarks",
    meta: indexHj84qFDJlxMeta || {},
    alias: indexHj84qFDJlxMeta?.alias || [],
    redirect: indexHj84qFDJlxMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/bookmarks/index.vue").then(m => m.default || m)
  },
  {
    name: indexHj84qFDJlxMeta?.name ?? "bookmarks___zh",
    path: indexHj84qFDJlxMeta?.path ?? "/bookmarks",
    meta: indexHj84qFDJlxMeta || {},
    alias: indexHj84qFDJlxMeta?.alias || [],
    redirect: indexHj84qFDJlxMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/bookmarks/index.vue").then(m => m.default || m)
  },
  {
    name: index1PjdqF49aaMeta?.name ?? "confirm___en",
    path: index1PjdqF49aaMeta?.path ?? "/en/confirm",
    meta: index1PjdqF49aaMeta || {},
    alias: index1PjdqF49aaMeta?.alias || [],
    redirect: index1PjdqF49aaMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: index1PjdqF49aaMeta?.name ?? "confirm___zh",
    path: index1PjdqF49aaMeta?.path ?? "/confirm",
    meta: index1PjdqF49aaMeta || {},
    alias: index1PjdqF49aaMeta?.alias || [],
    redirect: index1PjdqF49aaMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936eHd8C5uhKMeta?.name ?? "DM-Message-id___en",
    path: _91id_936eHd8C5uhKMeta?.path ?? "/en/DM/Message/:id()",
    meta: _91id_936eHd8C5uhKMeta || {},
    alias: _91id_936eHd8C5uhKMeta?.alias || [],
    redirect: _91id_936eHd8C5uhKMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/DM/Message/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936eHd8C5uhKMeta?.name ?? "DM-Message-id___zh",
    path: _91id_936eHd8C5uhKMeta?.path ?? "/DM/Message/:id()",
    meta: _91id_936eHd8C5uhKMeta || {},
    alias: _91id_936eHd8C5uhKMeta?.alias || [],
    redirect: _91id_936eHd8C5uhKMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/DM/Message/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dwy5UkJeOPMeta?.name ?? "embed-acct-status-id___en",
    path: _91id_93dwy5UkJeOPMeta?.path ?? "/en/embed/:acct()/status/:id()",
    meta: _91id_93dwy5UkJeOPMeta || {},
    alias: _91id_93dwy5UkJeOPMeta?.alias || [],
    redirect: _91id_93dwy5UkJeOPMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/embed/[acct]/status/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dwy5UkJeOPMeta?.name ?? "embed-acct-status-id___zh",
    path: _91id_93dwy5UkJeOPMeta?.path ?? "/embed/:acct()/status/:id()",
    meta: _91id_93dwy5UkJeOPMeta || {},
    alias: _91id_93dwy5UkJeOPMeta?.alias || [],
    redirect: _91id_93dwy5UkJeOPMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/embed/[acct]/status/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_tag_93DmXbjseTOLMeta?.name ?? "explore-hashtag-_tag___en",
    path: _91_tag_93DmXbjseTOLMeta?.path ?? "/en/explore/hashtag/:_tag()",
    meta: _91_tag_93DmXbjseTOLMeta || {},
    alias: _91_tag_93DmXbjseTOLMeta?.alias || [],
    redirect: _91_tag_93DmXbjseTOLMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/explore/hashtag/[_tag].vue").then(m => m.default || m)
  },
  {
    name: _91_tag_93DmXbjseTOLMeta?.name ?? "explore-hashtag-_tag___zh",
    path: _91_tag_93DmXbjseTOLMeta?.path ?? "/explore/hashtag/:_tag()",
    meta: _91_tag_93DmXbjseTOLMeta || {},
    alias: _91_tag_93DmXbjseTOLMeta?.alias || [],
    redirect: _91_tag_93DmXbjseTOLMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/explore/hashtag/[_tag].vue").then(m => m.default || m)
  },
  {
    name: index9MEUjruvCAMeta?.name ?? "followRequests___en",
    path: index9MEUjruvCAMeta?.path ?? "/en/followRequests",
    meta: index9MEUjruvCAMeta || {},
    alias: index9MEUjruvCAMeta?.alias || [],
    redirect: index9MEUjruvCAMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/followRequests/index.vue").then(m => m.default || m)
  },
  {
    name: index9MEUjruvCAMeta?.name ?? "followRequests___zh",
    path: index9MEUjruvCAMeta?.path ?? "/followRequests",
    meta: index9MEUjruvCAMeta || {},
    alias: index9MEUjruvCAMeta?.alias || [],
    redirect: index9MEUjruvCAMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/followRequests/index.vue").then(m => m.default || m)
  },
  {
    name: indexKuLlmCumD2Meta?.name ?? "index___en",
    path: indexKuLlmCumD2Meta?.path ?? "/en",
    meta: indexKuLlmCumD2Meta || {},
    alias: indexKuLlmCumD2Meta?.alias || [],
    redirect: indexKuLlmCumD2Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKuLlmCumD2Meta?.name ?? "index___zh",
    path: indexKuLlmCumD2Meta?.path ?? "/",
    meta: indexKuLlmCumD2Meta || {},
    alias: indexKuLlmCumD2Meta?.alias || [],
    redirect: indexKuLlmCumD2Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jf2dyzJmlJMeta?.name ?? "lists-id___en",
    path: _91id_93Jf2dyzJmlJMeta?.path ?? "/en/lists/:id()",
    meta: _91id_93Jf2dyzJmlJMeta || {},
    alias: _91id_93Jf2dyzJmlJMeta?.alias || [],
    redirect: _91id_93Jf2dyzJmlJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jf2dyzJmlJMeta?.name ?? "lists-id___zh",
    path: _91id_93Jf2dyzJmlJMeta?.path ?? "/lists/:id()",
    meta: _91id_93Jf2dyzJmlJMeta || {},
    alias: _91id_93Jf2dyzJmlJMeta?.alias || [],
    redirect: _91id_93Jf2dyzJmlJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDQLvg9WPrmMeta?.name ?? "lists___en",
    path: indexDQLvg9WPrmMeta?.path ?? "/en/lists",
    meta: indexDQLvg9WPrmMeta || {},
    alias: indexDQLvg9WPrmMeta?.alias || [],
    redirect: indexDQLvg9WPrmMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/lists/index.vue").then(m => m.default || m)
  },
  {
    name: indexDQLvg9WPrmMeta?.name ?? "lists___zh",
    path: indexDQLvg9WPrmMeta?.path ?? "/lists",
    meta: indexDQLvg9WPrmMeta || {},
    alias: indexDQLvg9WPrmMeta?.alias || [],
    redirect: indexDQLvg9WPrmMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/lists/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91toId_93_93O5X4D4W2AXMeta?.name ?? "Message-toId___en",
    path: _91_91toId_93_93O5X4D4W2AXMeta?.path ?? "/en/Message/:toId?",
    meta: _91_91toId_93_93O5X4D4W2AXMeta || {},
    alias: _91_91toId_93_93O5X4D4W2AXMeta?.alias || [],
    redirect: _91_91toId_93_93O5X4D4W2AXMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Message/[[toId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91toId_93_93O5X4D4W2AXMeta?.name ?? "Message-toId___zh",
    path: _91_91toId_93_93O5X4D4W2AXMeta?.path ?? "/Message/:toId?",
    meta: _91_91toId_93_93O5X4D4W2AXMeta || {},
    alias: _91_91toId_93_93O5X4D4W2AXMeta?.alias || [],
    redirect: _91_91toId_93_93O5X4D4W2AXMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Message/[[toId]].vue").then(m => m.default || m)
  },
  {
    name: indexulNSVtDzmhMeta?.name ?? "mutes___en",
    path: indexulNSVtDzmhMeta?.path ?? "/en/mutes",
    meta: indexulNSVtDzmhMeta || {},
    alias: indexulNSVtDzmhMeta?.alias || [],
    redirect: indexulNSVtDzmhMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/mutes/index.vue").then(m => m.default || m)
  },
  {
    name: indexulNSVtDzmhMeta?.name ?? "mutes___zh",
    path: indexulNSVtDzmhMeta?.path ?? "/mutes",
    meta: indexulNSVtDzmhMeta || {},
    alias: indexulNSVtDzmhMeta?.alias || [],
    redirect: indexulNSVtDzmhMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/mutes/index.vue").then(m => m.default || m)
  },
  {
    name: listVGKL7kOGPgMeta?.name ?? "notifications-components-list___en",
    path: listVGKL7kOGPgMeta?.path ?? "/en/notifications/components/list",
    meta: listVGKL7kOGPgMeta || {},
    alias: listVGKL7kOGPgMeta?.alias || [],
    redirect: listVGKL7kOGPgMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/components/list.vue").then(m => m.default || m)
  },
  {
    name: listVGKL7kOGPgMeta?.name ?? "notifications-components-list___zh",
    path: listVGKL7kOGPgMeta?.path ?? "/notifications/components/list",
    meta: listVGKL7kOGPgMeta || {},
    alias: listVGKL7kOGPgMeta?.alias || [],
    redirect: listVGKL7kOGPgMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/components/list.vue").then(m => m.default || m)
  },
  {
    name: notificationmfBVZ71W3xMeta?.name ?? "notifications-components-notification___en",
    path: notificationmfBVZ71W3xMeta?.path ?? "/en/notifications/components/notification",
    meta: notificationmfBVZ71W3xMeta || {},
    alias: notificationmfBVZ71W3xMeta?.alias || [],
    redirect: notificationmfBVZ71W3xMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/components/notification.vue").then(m => m.default || m)
  },
  {
    name: notificationmfBVZ71W3xMeta?.name ?? "notifications-components-notification___zh",
    path: notificationmfBVZ71W3xMeta?.path ?? "/notifications/components/notification",
    meta: notificationmfBVZ71W3xMeta || {},
    alias: notificationmfBVZ71W3xMeta?.alias || [],
    redirect: notificationmfBVZ71W3xMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/components/notification.vue").then(m => m.default || m)
  },
  {
    name: indexhHug3F68xJMeta?.name ?? "notifications___en",
    path: indexhHug3F68xJMeta?.path ?? "/en/notifications",
    meta: indexhHug3F68xJMeta || {},
    alias: indexhHug3F68xJMeta?.alias || [],
    redirect: indexhHug3F68xJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexhHug3F68xJMeta?.name ?? "notifications___zh",
    path: indexhHug3F68xJMeta?.path ?? "/notifications",
    meta: indexhHug3F68xJMeta || {},
    alias: indexhHug3F68xJMeta?.alias || [],
    redirect: indexhHug3F68xJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: index4PEouCpCIhMeta?.name ?? "password-reset___en",
    path: index4PEouCpCIhMeta?.path ?? "/en/password/reset",
    meta: index4PEouCpCIhMeta || {},
    alias: index4PEouCpCIhMeta?.alias || [],
    redirect: index4PEouCpCIhMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: index4PEouCpCIhMeta?.name ?? "password-reset___zh",
    path: index4PEouCpCIhMeta?.path ?? "/password/reset",
    meta: index4PEouCpCIhMeta || {},
    alias: index4PEouCpCIhMeta?.alias || [],
    redirect: index4PEouCpCIhMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: index66JXbCQmmMMeta?.name ?? "public-common___en",
    path: index66JXbCQmmMMeta?.path ?? "/en/public/common",
    meta: index66JXbCQmmMMeta || {},
    alias: index66JXbCQmmMMeta?.alias || [],
    redirect: index66JXbCQmmMMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/public/common/index.vue").then(m => m.default || m)
  },
  {
    name: index66JXbCQmmMMeta?.name ?? "public-common___zh",
    path: index66JXbCQmmMMeta?.path ?? "/public/common",
    meta: index66JXbCQmmMMeta || {},
    alias: index66JXbCQmmMMeta?.alias || [],
    redirect: index66JXbCQmmMMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/public/common/index.vue").then(m => m.default || m)
  },
  {
    name: indexQc13JLv7haMeta?.name ?? "public-local___en",
    path: indexQc13JLv7haMeta?.path ?? "/en/public/local",
    meta: indexQc13JLv7haMeta || {},
    alias: indexQc13JLv7haMeta?.alias || [],
    redirect: indexQc13JLv7haMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/public/local/index.vue").then(m => m.default || m)
  },
  {
    name: indexQc13JLv7haMeta?.name ?? "public-local___zh",
    path: indexQc13JLv7haMeta?.path ?? "/public/local",
    meta: indexQc13JLv7haMeta || {},
    alias: indexQc13JLv7haMeta?.alias || [],
    redirect: indexQc13JLv7haMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/public/local/index.vue").then(m => m.default || m)
  },
  {
    name: registeraAjicikz2eMeta?.name ?? "register-components-register___en",
    path: registeraAjicikz2eMeta?.path ?? "/en/register/components/register",
    meta: registeraAjicikz2eMeta || {},
    alias: registeraAjicikz2eMeta?.alias || [],
    redirect: registeraAjicikz2eMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/register.vue").then(m => m.default || m)
  },
  {
    name: registeraAjicikz2eMeta?.name ?? "register-components-register___zh",
    path: registeraAjicikz2eMeta?.path ?? "/register/components/register",
    meta: registeraAjicikz2eMeta || {},
    alias: registeraAjicikz2eMeta?.alias || [],
    redirect: registeraAjicikz2eMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/register.vue").then(m => m.default || m)
  },
  {
    name: rulesver12DXwzzMeta?.name ?? "register-components-rules___en",
    path: rulesver12DXwzzMeta?.path ?? "/en/register/components/rules",
    meta: rulesver12DXwzzMeta || {},
    alias: rulesver12DXwzzMeta?.alias || [],
    redirect: rulesver12DXwzzMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/rules.vue").then(m => m.default || m)
  },
  {
    name: rulesver12DXwzzMeta?.name ?? "register-components-rules___zh",
    path: rulesver12DXwzzMeta?.path ?? "/register/components/rules",
    meta: rulesver12DXwzzMeta || {},
    alias: rulesver12DXwzzMeta?.alias || [],
    redirect: rulesver12DXwzzMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/rules.vue").then(m => m.default || m)
  },
  {
    name: verifyEmailrNcFMh4TzJMeta?.name ?? "register-components-verifyEmail___en",
    path: verifyEmailrNcFMh4TzJMeta?.path ?? "/en/register/components/verifyEmail",
    meta: verifyEmailrNcFMh4TzJMeta || {},
    alias: verifyEmailrNcFMh4TzJMeta?.alias || [],
    redirect: verifyEmailrNcFMh4TzJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/verifyEmail.vue").then(m => m.default || m)
  },
  {
    name: verifyEmailrNcFMh4TzJMeta?.name ?? "register-components-verifyEmail___zh",
    path: verifyEmailrNcFMh4TzJMeta?.path ?? "/register/components/verifyEmail",
    meta: verifyEmailrNcFMh4TzJMeta || {},
    alias: verifyEmailrNcFMh4TzJMeta?.alias || [],
    redirect: verifyEmailrNcFMh4TzJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/components/verifyEmail.vue").then(m => m.default || m)
  },
  {
    name: indexV2o3RalCsRMeta?.name ?? "register___en",
    path: indexV2o3RalCsRMeta?.path ?? "/en/register",
    meta: indexV2o3RalCsRMeta || {},
    alias: indexV2o3RalCsRMeta?.alias || [],
    redirect: indexV2o3RalCsRMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexV2o3RalCsRMeta?.name ?? "register___zh",
    path: indexV2o3RalCsRMeta?.path ?? "/register",
    meta: indexV2o3RalCsRMeta || {},
    alias: indexV2o3RalCsRMeta?.alias || [],
    redirect: indexV2o3RalCsRMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: _91_type_93ZpDmNCzbPAMeta?.name ?? "Search-_type___en",
    path: _91_type_93ZpDmNCzbPAMeta?.path ?? "/en/Search/:_type()",
    meta: _91_type_93ZpDmNCzbPAMeta || {},
    alias: _91_type_93ZpDmNCzbPAMeta?.alias || [],
    redirect: _91_type_93ZpDmNCzbPAMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Search/[_type].vue").then(m => m.default || m)
  },
  {
    name: _91_type_93ZpDmNCzbPAMeta?.name ?? "Search-_type___zh",
    path: _91_type_93ZpDmNCzbPAMeta?.path ?? "/Search/:_type()",
    meta: _91_type_93ZpDmNCzbPAMeta || {},
    alias: _91_type_93ZpDmNCzbPAMeta?.alias || [],
    redirect: _91_type_93ZpDmNCzbPAMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Search/[_type].vue").then(m => m.default || m)
  },
  {
    name: index2epwJWfGdlMeta?.name ?? "Search___en",
    path: index2epwJWfGdlMeta?.path ?? "/en/Search",
    meta: index2epwJWfGdlMeta || {},
    alias: index2epwJWfGdlMeta?.alias || [],
    redirect: index2epwJWfGdlMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Search/index.vue").then(m => m.default || m)
  },
  {
    name: index2epwJWfGdlMeta?.name ?? "Search___zh",
    path: index2epwJWfGdlMeta?.path ?? "/Search",
    meta: index2epwJWfGdlMeta || {},
    alias: index2epwJWfGdlMeta?.alias || [],
    redirect: index2epwJWfGdlMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/Search/index.vue").then(m => m.default || m)
  },
  {
    name: indexyep89XGvHcMeta?.name ?? "send___en",
    path: indexyep89XGvHcMeta?.path ?? "/en/send",
    meta: indexyep89XGvHcMeta || {},
    alias: indexyep89XGvHcMeta?.alias || [],
    redirect: indexyep89XGvHcMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/send/index.vue").then(m => m.default || m)
  },
  {
    name: indexyep89XGvHcMeta?.name ?? "send___zh",
    path: indexyep89XGvHcMeta?.path ?? "/send",
    meta: indexyep89XGvHcMeta || {},
    alias: indexyep89XGvHcMeta?.alias || [],
    redirect: indexyep89XGvHcMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/send/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93quU0DZ0BiXMeta?.name ?? "settings-adminAccounts-detail-id___en",
    path: _91id_93quU0DZ0BiXMeta?.path ?? "/en/settings/adminAccounts/detail/:id()",
    meta: _91id_93quU0DZ0BiXMeta || {},
    alias: _91id_93quU0DZ0BiXMeta?.alias || [],
    redirect: _91id_93quU0DZ0BiXMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminAccounts/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93quU0DZ0BiXMeta?.name ?? "settings-adminAccounts-detail-id___zh",
    path: _91id_93quU0DZ0BiXMeta?.path ?? "/settings/adminAccounts/detail/:id()",
    meta: _91id_93quU0DZ0BiXMeta || {},
    alias: _91id_93quU0DZ0BiXMeta?.alias || [],
    redirect: _91id_93quU0DZ0BiXMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminAccounts/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoiqebpOf7ZMeta?.name ?? "settings-adminAccounts___en",
    path: indexoiqebpOf7ZMeta?.path ?? "/en/settings/adminAccounts",
    meta: indexoiqebpOf7ZMeta || {},
    alias: indexoiqebpOf7ZMeta?.alias || [],
    redirect: indexoiqebpOf7ZMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminAccounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexoiqebpOf7ZMeta?.name ?? "settings-adminAccounts___zh",
    path: indexoiqebpOf7ZMeta?.path ?? "/settings/adminAccounts",
    meta: indexoiqebpOf7ZMeta || {},
    alias: indexoiqebpOf7ZMeta?.alias || [],
    redirect: indexoiqebpOf7ZMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminAccounts/index.vue").then(m => m.default || m)
  },
  {
    name: editi2kgDog86JMeta?.name ?? "settings-adminFilters-edit___en",
    path: editi2kgDog86JMeta?.path ?? "/en/settings/adminFilters/edit",
    meta: editi2kgDog86JMeta || {},
    alias: editi2kgDog86JMeta?.alias || [],
    redirect: editi2kgDog86JMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/edit.vue").then(m => m.default || m)
  },
  {
    name: editi2kgDog86JMeta?.name ?? "settings-adminFilters-edit___zh",
    path: editi2kgDog86JMeta?.path ?? "/settings/adminFilters/edit",
    meta: editi2kgDog86JMeta || {},
    alias: editi2kgDog86JMeta?.alias || [],
    redirect: editi2kgDog86JMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/edit.vue").then(m => m.default || m)
  },
  {
    name: index34UD9wfn9ZMeta?.name ?? "settings-adminFilters___en",
    path: index34UD9wfn9ZMeta?.path ?? "/en/settings/adminFilters",
    meta: index34UD9wfn9ZMeta || {},
    alias: index34UD9wfn9ZMeta?.alias || [],
    redirect: index34UD9wfn9ZMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/index.vue").then(m => m.default || m)
  },
  {
    name: index34UD9wfn9ZMeta?.name ?? "settings-adminFilters___zh",
    path: index34UD9wfn9ZMeta?.path ?? "/settings/adminFilters",
    meta: index34UD9wfn9ZMeta || {},
    alias: index34UD9wfn9ZMeta?.alias || [],
    redirect: index34UD9wfn9ZMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/index.vue").then(m => m.default || m)
  },
  {
    name: newffbUjq2DsdMeta?.name ?? "settings-adminFilters-new___en",
    path: newffbUjq2DsdMeta?.path ?? "/en/settings/adminFilters/new",
    meta: newffbUjq2DsdMeta || {},
    alias: newffbUjq2DsdMeta?.alias || [],
    redirect: newffbUjq2DsdMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/new.vue").then(m => m.default || m)
  },
  {
    name: newffbUjq2DsdMeta?.name ?? "settings-adminFilters-new___zh",
    path: newffbUjq2DsdMeta?.path ?? "/settings/adminFilters/new",
    meta: newffbUjq2DsdMeta || {},
    alias: newffbUjq2DsdMeta?.alias || [],
    redirect: newffbUjq2DsdMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminFilters/new.vue").then(m => m.default || m)
  },
  {
    name: _91domain_93aabXLBaqToMeta?.name ?? "settings-adminInstances-detail-domain___en",
    path: _91domain_93aabXLBaqToMeta?.path ?? "/en/settings/adminInstances/detail/:domain()",
    meta: _91domain_93aabXLBaqToMeta || {},
    alias: _91domain_93aabXLBaqToMeta?.alias || [],
    redirect: _91domain_93aabXLBaqToMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminInstances/detail/[domain].vue").then(m => m.default || m)
  },
  {
    name: _91domain_93aabXLBaqToMeta?.name ?? "settings-adminInstances-detail-domain___zh",
    path: _91domain_93aabXLBaqToMeta?.path ?? "/settings/adminInstances/detail/:domain()",
    meta: _91domain_93aabXLBaqToMeta || {},
    alias: _91domain_93aabXLBaqToMeta?.alias || [],
    redirect: _91domain_93aabXLBaqToMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminInstances/detail/[domain].vue").then(m => m.default || m)
  },
  {
    name: indexTbg8k4UI7FMeta?.name ?? "settings-adminInstances___en",
    path: indexTbg8k4UI7FMeta?.path ?? "/en/settings/adminInstances",
    meta: indexTbg8k4UI7FMeta || {},
    alias: indexTbg8k4UI7FMeta?.alias || [],
    redirect: indexTbg8k4UI7FMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminInstances/index.vue").then(m => m.default || m)
  },
  {
    name: indexTbg8k4UI7FMeta?.name ?? "settings-adminInstances___zh",
    path: indexTbg8k4UI7FMeta?.path ?? "/settings/adminInstances",
    meta: indexTbg8k4UI7FMeta || {},
    alias: indexTbg8k4UI7FMeta?.alias || [],
    redirect: indexTbg8k4UI7FMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminInstances/index.vue").then(m => m.default || m)
  },
  {
    name: editG2QPyWkF6jMeta?.name ?? "settings-adminRoles-edit___en",
    path: editG2QPyWkF6jMeta?.path ?? "/en/settings/adminRoles/edit",
    meta: editG2QPyWkF6jMeta || {},
    alias: editG2QPyWkF6jMeta?.alias || [],
    redirect: editG2QPyWkF6jMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/edit.vue").then(m => m.default || m)
  },
  {
    name: editG2QPyWkF6jMeta?.name ?? "settings-adminRoles-edit___zh",
    path: editG2QPyWkF6jMeta?.path ?? "/settings/adminRoles/edit",
    meta: editG2QPyWkF6jMeta || {},
    alias: editG2QPyWkF6jMeta?.alias || [],
    redirect: editG2QPyWkF6jMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/edit.vue").then(m => m.default || m)
  },
  {
    name: indexe3bGp01hgZMeta?.name ?? "settings-adminRoles___en",
    path: indexe3bGp01hgZMeta?.path ?? "/en/settings/adminRoles",
    meta: indexe3bGp01hgZMeta || {},
    alias: indexe3bGp01hgZMeta?.alias || [],
    redirect: indexe3bGp01hgZMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/index.vue").then(m => m.default || m)
  },
  {
    name: indexe3bGp01hgZMeta?.name ?? "settings-adminRoles___zh",
    path: indexe3bGp01hgZMeta?.path ?? "/settings/adminRoles",
    meta: indexe3bGp01hgZMeta || {},
    alias: indexe3bGp01hgZMeta?.alias || [],
    redirect: indexe3bGp01hgZMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/index.vue").then(m => m.default || m)
  },
  {
    name: newwkB5a5PFICMeta?.name ?? "settings-adminRoles-new___en",
    path: newwkB5a5PFICMeta?.path ?? "/en/settings/adminRoles/new",
    meta: newwkB5a5PFICMeta || {},
    alias: newwkB5a5PFICMeta?.alias || [],
    redirect: newwkB5a5PFICMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/new.vue").then(m => m.default || m)
  },
  {
    name: newwkB5a5PFICMeta?.name ?? "settings-adminRoles-new___zh",
    path: newwkB5a5PFICMeta?.path ?? "/settings/adminRoles/new",
    meta: newwkB5a5PFICMeta || {},
    alias: newwkB5a5PFICMeta?.alias || [],
    redirect: newwkB5a5PFICMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/adminRoles/new.vue").then(m => m.default || m)
  },
  {
    name: indexNKLGV0OWOCMeta?.name ?? "settings-dashboard___en",
    path: indexNKLGV0OWOCMeta?.path ?? "/en/settings/dashboard",
    meta: indexNKLGV0OWOCMeta || {},
    alias: indexNKLGV0OWOCMeta?.alias || [],
    redirect: indexNKLGV0OWOCMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexNKLGV0OWOCMeta?.name ?? "settings-dashboard___zh",
    path: indexNKLGV0OWOCMeta?.path ?? "/settings/dashboard",
    meta: indexNKLGV0OWOCMeta || {},
    alias: indexNKLGV0OWOCMeta?.alias || [],
    redirect: indexNKLGV0OWOCMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexx1eA7tzdYWMeta?.name ?? "settings-emailDomainBlocks___en",
    path: indexx1eA7tzdYWMeta?.path ?? "/en/settings/emailDomainBlocks",
    meta: indexx1eA7tzdYWMeta || {},
    alias: indexx1eA7tzdYWMeta?.alias || [],
    redirect: indexx1eA7tzdYWMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/emailDomainBlocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexx1eA7tzdYWMeta?.name ?? "settings-emailDomainBlocks___zh",
    path: indexx1eA7tzdYWMeta?.path ?? "/settings/emailDomainBlocks",
    meta: indexx1eA7tzdYWMeta || {},
    alias: indexx1eA7tzdYWMeta?.alias || [],
    redirect: indexx1eA7tzdYWMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/emailDomainBlocks/index.vue").then(m => m.default || m)
  },
  {
    name: newo8l4RGh6qUMeta?.name ?? "settings-emailDomainBlocks-new___en",
    path: newo8l4RGh6qUMeta?.path ?? "/en/settings/emailDomainBlocks/new",
    meta: newo8l4RGh6qUMeta || {},
    alias: newo8l4RGh6qUMeta?.alias || [],
    redirect: newo8l4RGh6qUMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/emailDomainBlocks/new.vue").then(m => m.default || m)
  },
  {
    name: newo8l4RGh6qUMeta?.name ?? "settings-emailDomainBlocks-new___zh",
    path: newo8l4RGh6qUMeta?.path ?? "/settings/emailDomainBlocks/new",
    meta: newo8l4RGh6qUMeta || {},
    alias: newo8l4RGh6qUMeta?.alias || [],
    redirect: newo8l4RGh6qUMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/emailDomainBlocks/new.vue").then(m => m.default || m)
  },
  {
    name: index8TqdMcGDxzMeta?.name ?? "settings-export___en",
    path: index8TqdMcGDxzMeta?.path ?? "/en/settings/export",
    meta: index8TqdMcGDxzMeta || {},
    alias: index8TqdMcGDxzMeta?.alias || [],
    redirect: index8TqdMcGDxzMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/export/index.vue").then(m => m.default || m)
  },
  {
    name: index8TqdMcGDxzMeta?.name ?? "settings-export___zh",
    path: index8TqdMcGDxzMeta?.path ?? "/settings/export",
    meta: index8TqdMcGDxzMeta || {},
    alias: index8TqdMcGDxzMeta?.alias || [],
    redirect: index8TqdMcGDxzMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/export/index.vue").then(m => m.default || m)
  },
  {
    name: configdXT5FQ5kllMeta?.name ?? "settings-followRecommend-config___en",
    path: configdXT5FQ5kllMeta?.path ?? "/en/settings/followRecommend/config",
    meta: configdXT5FQ5kllMeta || {},
    alias: configdXT5FQ5kllMeta?.alias || [],
    redirect: configdXT5FQ5kllMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/followRecommend/config.js").then(m => m.default || m)
  },
  {
    name: configdXT5FQ5kllMeta?.name ?? "settings-followRecommend-config___zh",
    path: configdXT5FQ5kllMeta?.path ?? "/settings/followRecommend/config",
    meta: configdXT5FQ5kllMeta || {},
    alias: configdXT5FQ5kllMeta?.alias || [],
    redirect: configdXT5FQ5kllMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/followRecommend/config.js").then(m => m.default || m)
  },
  {
    name: indexg9XzoskwPgMeta?.name ?? "settings-followRecommend___en",
    path: indexg9XzoskwPgMeta?.path ?? "/en/settings/followRecommend",
    meta: indexg9XzoskwPgMeta || {},
    alias: indexg9XzoskwPgMeta?.alias || [],
    redirect: indexg9XzoskwPgMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/followRecommend/index.vue").then(m => m.default || m)
  },
  {
    name: indexg9XzoskwPgMeta?.name ?? "settings-followRecommend___zh",
    path: indexg9XzoskwPgMeta?.path ?? "/settings/followRecommend",
    meta: indexg9XzoskwPgMeta || {},
    alias: indexg9XzoskwPgMeta?.alias || [],
    redirect: indexg9XzoskwPgMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/followRecommend/index.vue").then(m => m.default || m)
  },
  {
    name: indexjj9qlcomA3Meta?.name ?? "settings-import___en",
    path: indexjj9qlcomA3Meta?.path ?? "/en/settings/import",
    meta: indexjj9qlcomA3Meta || {},
    alias: indexjj9qlcomA3Meta?.alias || [],
    redirect: indexjj9qlcomA3Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexjj9qlcomA3Meta?.name ?? "settings-import___zh",
    path: indexjj9qlcomA3Meta?.path ?? "/settings/import",
    meta: indexjj9qlcomA3Meta || {},
    alias: indexjj9qlcomA3Meta?.alias || [],
    redirect: indexjj9qlcomA3Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/import/index.vue").then(m => m.default || m)
  },
  {
    name: addYEzoDNhEKJMeta?.name ?? "settings-ipRules-add___en",
    path: addYEzoDNhEKJMeta?.path ?? "/en/settings/ipRules/add",
    meta: addYEzoDNhEKJMeta || {},
    alias: addYEzoDNhEKJMeta?.alias || [],
    redirect: addYEzoDNhEKJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/add.vue").then(m => m.default || m)
  },
  {
    name: addYEzoDNhEKJMeta?.name ?? "settings-ipRules-add___zh",
    path: addYEzoDNhEKJMeta?.path ?? "/settings/ipRules/add",
    meta: addYEzoDNhEKJMeta || {},
    alias: addYEzoDNhEKJMeta?.alias || [],
    redirect: addYEzoDNhEKJMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/add.vue").then(m => m.default || m)
  },
  {
    name: configYTGvvalqPRMeta?.name ?? "settings-ipRules-config___en",
    path: configYTGvvalqPRMeta?.path ?? "/en/settings/ipRules/config",
    meta: configYTGvvalqPRMeta || {},
    alias: configYTGvvalqPRMeta?.alias || [],
    redirect: configYTGvvalqPRMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/config.js").then(m => m.default || m)
  },
  {
    name: configYTGvvalqPRMeta?.name ?? "settings-ipRules-config___zh",
    path: configYTGvvalqPRMeta?.path ?? "/settings/ipRules/config",
    meta: configYTGvvalqPRMeta || {},
    alias: configYTGvvalqPRMeta?.alias || [],
    redirect: configYTGvvalqPRMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/config.js").then(m => m.default || m)
  },
  {
    name: indexs6Xv2qX75NMeta?.name ?? "settings-ipRules___en",
    path: indexs6Xv2qX75NMeta?.path ?? "/en/settings/ipRules",
    meta: indexs6Xv2qX75NMeta || {},
    alias: indexs6Xv2qX75NMeta?.alias || [],
    redirect: indexs6Xv2qX75NMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/index.vue").then(m => m.default || m)
  },
  {
    name: indexs6Xv2qX75NMeta?.name ?? "settings-ipRules___zh",
    path: indexs6Xv2qX75NMeta?.path ?? "/settings/ipRules",
    meta: indexs6Xv2qX75NMeta || {},
    alias: indexs6Xv2qX75NMeta?.alias || [],
    redirect: indexs6Xv2qX75NMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/ipRules/index.vue").then(m => m.default || m)
  },
  {
    name: index7FMizl1yIVMeta?.name ?? "settings-nsq___en",
    path: index7FMizl1yIVMeta?.path ?? "/en/settings/nsq",
    meta: index7FMizl1yIVMeta || {},
    alias: index7FMizl1yIVMeta?.alias || [],
    redirect: index7FMizl1yIVMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/nsq/index.vue").then(m => m.default || m)
  },
  {
    name: index7FMizl1yIVMeta?.name ?? "settings-nsq___zh",
    path: index7FMizl1yIVMeta?.path ?? "/settings/nsq",
    meta: index7FMizl1yIVMeta || {},
    alias: index7FMizl1yIVMeta?.alias || [],
    redirect: index7FMizl1yIVMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/nsq/index.vue").then(m => m.default || m)
  },
  {
    name: indexz2dF71HFLTMeta?.name ?? "settings-profile___en",
    path: indexz2dF71HFLTMeta?.path ?? "/en/settings/profile",
    meta: indexz2dF71HFLTMeta || {},
    alias: indexz2dF71HFLTMeta?.alias || [],
    redirect: indexz2dF71HFLTMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexz2dF71HFLTMeta?.name ?? "settings-profile___zh",
    path: indexz2dF71HFLTMeta?.path ?? "/settings/profile",
    meta: indexz2dF71HFLTMeta || {},
    alias: indexz2dF71HFLTMeta?.alias || [],
    redirect: indexz2dF71HFLTMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexEN5QR9p1LtMeta?.name ?? "settings-relayStation___en",
    path: indexEN5QR9p1LtMeta?.path ?? "/en/settings/relayStation",
    meta: indexEN5QR9p1LtMeta || {},
    alias: indexEN5QR9p1LtMeta?.alias || [],
    redirect: indexEN5QR9p1LtMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/relayStation/index.vue").then(m => m.default || m)
  },
  {
    name: indexEN5QR9p1LtMeta?.name ?? "settings-relayStation___zh",
    path: indexEN5QR9p1LtMeta?.path ?? "/settings/relayStation",
    meta: indexEN5QR9p1LtMeta || {},
    alias: indexEN5QR9p1LtMeta?.alias || [],
    redirect: indexEN5QR9p1LtMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/relayStation/index.vue").then(m => m.default || m)
  },
  {
    name: new0P7iVRUZ7aMeta?.name ?? "settings-relayStation-new___en",
    path: new0P7iVRUZ7aMeta?.path ?? "/en/settings/relayStation/new",
    meta: new0P7iVRUZ7aMeta || {},
    alias: new0P7iVRUZ7aMeta?.alias || [],
    redirect: new0P7iVRUZ7aMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/relayStation/new.vue").then(m => m.default || m)
  },
  {
    name: new0P7iVRUZ7aMeta?.name ?? "settings-relayStation-new___zh",
    path: new0P7iVRUZ7aMeta?.path ?? "/settings/relayStation/new",
    meta: new0P7iVRUZ7aMeta || {},
    alias: new0P7iVRUZ7aMeta?.alias || [],
    redirect: new0P7iVRUZ7aMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/relayStation/new.vue").then(m => m.default || m)
  },
  {
    name: indexCfPwC9rGoOMeta?.name ?? "settings-report___en",
    path: indexCfPwC9rGoOMeta?.path ?? "/en/settings/report",
    meta: indexCfPwC9rGoOMeta || {},
    alias: indexCfPwC9rGoOMeta?.alias || [],
    redirect: indexCfPwC9rGoOMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/report/index.vue").then(m => m.default || m)
  },
  {
    name: indexCfPwC9rGoOMeta?.name ?? "settings-report___zh",
    path: indexCfPwC9rGoOMeta?.path ?? "/settings/report",
    meta: indexCfPwC9rGoOMeta || {},
    alias: indexCfPwC9rGoOMeta?.alias || [],
    redirect: indexCfPwC9rGoOMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/report/index.vue").then(m => m.default || m)
  },
  {
    name: indexQuHi0v4h4jMeta?.name ?? "settings-rule___en",
    path: indexQuHi0v4h4jMeta?.path ?? "/en/settings/rule",
    meta: indexQuHi0v4h4jMeta || {},
    alias: indexQuHi0v4h4jMeta?.alias || [],
    redirect: indexQuHi0v4h4jMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/rule/index.vue").then(m => m.default || m)
  },
  {
    name: indexQuHi0v4h4jMeta?.name ?? "settings-rule___zh",
    path: indexQuHi0v4h4jMeta?.path ?? "/settings/rule",
    meta: indexQuHi0v4h4jMeta || {},
    alias: indexQuHi0v4h4jMeta?.alias || [],
    redirect: indexQuHi0v4h4jMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/rule/index.vue").then(m => m.default || m)
  },
  {
    name: index4iPPjRwiHUMeta?.name ?? "settings-serverSetup___en",
    path: index4iPPjRwiHUMeta?.path ?? "/en/settings/serverSetup",
    meta: index4iPPjRwiHUMeta || {},
    alias: index4iPPjRwiHUMeta?.alias || [],
    redirect: index4iPPjRwiHUMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/serverSetup/index.vue").then(m => m.default || m)
  },
  {
    name: index4iPPjRwiHUMeta?.name ?? "settings-serverSetup___zh",
    path: index4iPPjRwiHUMeta?.path ?? "/settings/serverSetup",
    meta: index4iPPjRwiHUMeta || {},
    alias: index4iPPjRwiHUMeta?.alias || [],
    redirect: index4iPPjRwiHUMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/serverSetup/index.vue").then(m => m.default || m)
  },
  {
    name: indexxMyLzV2jAvMeta?.name ?? "settings-subscription___en",
    path: indexxMyLzV2jAvMeta?.path ?? "/en/settings/subscription",
    meta: indexxMyLzV2jAvMeta || {},
    alias: indexxMyLzV2jAvMeta?.alias || [],
    redirect: indexxMyLzV2jAvMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: indexxMyLzV2jAvMeta?.name ?? "settings-subscription___zh",
    path: indexxMyLzV2jAvMeta?.path ?? "/settings/subscription",
    meta: indexxMyLzV2jAvMeta || {},
    alias: indexxMyLzV2jAvMeta?.alias || [],
    redirect: indexxMyLzV2jAvMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/settings/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: indexg18OdWe2OXMeta?.name ?? "subscribed___en",
    path: indexg18OdWe2OXMeta?.path ?? "/en/subscribed",
    meta: indexg18OdWe2OXMeta || {},
    alias: indexg18OdWe2OXMeta?.alias || [],
    redirect: indexg18OdWe2OXMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/subscribed/index.vue").then(m => m.default || m)
  },
  {
    name: indexg18OdWe2OXMeta?.name ?? "subscribed___zh",
    path: indexg18OdWe2OXMeta?.path ?? "/subscribed",
    meta: indexg18OdWe2OXMeta || {},
    alias: indexg18OdWe2OXMeta?.alias || [],
    redirect: indexg18OdWe2OXMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/subscribed/index.vue").then(m => m.default || m)
  },
  {
    name: followerzJuYy12239Meta?.name ?? "User-acct-follower___en",
    path: followerzJuYy12239Meta?.path ?? "/en/User/:acct()/follower",
    meta: followerzJuYy12239Meta || {},
    alias: followerzJuYy12239Meta?.alias || [],
    redirect: followerzJuYy12239Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/follower.vue").then(m => m.default || m)
  },
  {
    name: followerzJuYy12239Meta?.name ?? "User-acct-follower___zh",
    path: followerzJuYy12239Meta?.path ?? "/User/:acct()/follower",
    meta: followerzJuYy12239Meta || {},
    alias: followerzJuYy12239Meta?.alias || [],
    redirect: followerzJuYy12239Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/follower.vue").then(m => m.default || m)
  },
  {
    name: following8MtoSQTtauMeta?.name ?? "User-acct-following___en",
    path: following8MtoSQTtauMeta?.path ?? "/en/User/:acct()/following",
    meta: following8MtoSQTtauMeta || {},
    alias: following8MtoSQTtauMeta?.alias || [],
    redirect: following8MtoSQTtauMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/following.vue").then(m => m.default || m)
  },
  {
    name: following8MtoSQTtauMeta?.name ?? "User-acct-following___zh",
    path: following8MtoSQTtauMeta?.path ?? "/User/:acct()/following",
    meta: following8MtoSQTtauMeta || {},
    alias: following8MtoSQTtauMeta?.alias || [],
    redirect: following8MtoSQTtauMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/following.vue").then(m => m.default || m)
  },
  {
    name: indexL5zv60byO6Meta?.name ?? "User-acct___en",
    path: indexL5zv60byO6Meta?.path ?? "/en/User/:acct()",
    meta: indexL5zv60byO6Meta || {},
    alias: indexL5zv60byO6Meta?.alias || [],
    redirect: indexL5zv60byO6Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5zv60byO6Meta?.name ?? "User-acct___zh",
    path: indexL5zv60byO6Meta?.path ?? "/User/:acct()",
    meta: indexL5zv60byO6Meta || {},
    alias: indexL5zv60byO6Meta?.alias || [],
    redirect: indexL5zv60byO6Meta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93i8tgZw29SFMeta?.name ?? "User-acct-status-id___en",
    path: _91id_93i8tgZw29SFMeta?.path ?? "/en/User/:acct()/status/:id()",
    meta: _91id_93i8tgZw29SFMeta || {},
    alias: _91id_93i8tgZw29SFMeta?.alias || [],
    redirect: _91id_93i8tgZw29SFMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/status/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93i8tgZw29SFMeta?.name ?? "User-acct-status-id___zh",
    path: _91id_93i8tgZw29SFMeta?.path ?? "/User/:acct()/status/:id()",
    meta: _91id_93i8tgZw29SFMeta || {},
    alias: _91id_93i8tgZw29SFMeta?.alias || [],
    redirect: _91id_93i8tgZw29SFMeta?.redirect,
    component: () => import("/Users/harley/Desktop/project/mastodom/mastodon-nuxt/pages/User/[acct]/status/[id].vue").then(m => m.default || m)
  }
]
/*
 * @Author: harley
 * @Date: 2024-04-11 17:00:48
 * @LastEditors: harley
 * @Description:
 */
export const ipRulesMap = {
  1: 'admin.ipRules.limitRegistration',
  2: 'admin.ipRules.blockAccountRegistration',
  3: 'admin.ipRules.blockAccess'
}
